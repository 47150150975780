
import { defineComponent, computed, Ref, PropType } from 'vue'
import MoreInfo from '@/components/common/MoreInfo.vue'
import dayjs from 'dayjs'
import { fromDecimalToUnit } from '@/libs/everpay-js/utils/util'
import { getExplorerUrl, formatMoney, formatNumber, formatTargetChainType, formatTokenTag } from '@/libs/utils'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import capitalize from 'lodash/capitalize'
import ChainTypeLogo from './ChainTypeLogo.vue'
import { ChainType } from '@/libs/everpay-js'
export default defineComponent({
  components: {
    MoreInfo,
    ChainTypeLogo
  },
  props: {
    txInfo: {
      type: Object as PropType<any>,
      default: () => {}
    },
    isBundleTx: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const arId = computed<string>(() => route.params.arId ? route.params.arId.toString() : '')
    const accountId = computed(() => route.params.accountId ? route.params.accountId.toString() : '')
    const tokens = computed(() => store.state.tokenList)
    const datetime = dayjs(+props.txInfo.nonce).format('YYYY-MM-DD HH:mm:ss')
    const token = computed(() => formatTokenTag(props.txInfo, tokens.value))
    const targetChainType:Ref<any> = computed(() => {
      return formatTargetChainType(props.txInfo)
    })
    const displayWarningImg = computed(() => {
      try {
        const status = JSON.parse(props.isBundleTx ? props.txInfo.bundleErr : props.txInfo.internalStatus).status !== 'success'
        return status
      } catch {
        return true
      }
    })
    const burnToUrl = computed(() => {
      return props.txInfo.action === 'burn'
        ? getExplorerUrl({
          type: 'address',
          symbol: token.value?.symbol ?? '',
          value: props.txInfo.to
        }, targetChainType.value)
        : ''
    })
    const targetChainTxHashUrl = computed(() => {
      return props.txInfo.targetChainTxHash
        ? getExplorerUrl({
          type: 'tx',
          symbol: token.value?.symbol ?? '',
          value: targetChainType.value === ChainType.psntest ? props.txInfo.everHash : props.txInfo.targetChainTxHash
        }, targetChainType.value)
        : ''
    })
    const formatInfo = (info: string) => {
      return info.toString().slice(0, 8) + '...'
    }
    const totalAmount = (amount:string) => {
      return fromDecimalToUnit(amount, token.value?.decimals || 18)
    }
    const isOmitAmount = (amount:string) => {
      return totalAmount(amount).replaceAll(',', '').replace('.', '').length > 6
    }
    const getTokenSymbol = () => {
      if (token.value) {
        return token.value?.symbol
      } else if (props.txInfo.tokenSymbol) {
        return props.txInfo.tokenSymbol
      } else if (props.txInfo.tag) {
        return (props.txInfo.tag as string).split('-')[1].toUpperCase()
      } else {
        return 'undefined'
      }
    }
    const txsAmount = (amount:any) => {
      const amountInfo = totalAmount(amount)
      if (isOmitAmount(amount)) {
        return formatNumber(amountInfo, 6) + '...' + (getTokenSymbol())
      }
      return formatMoney(amountInfo) + ' ' + (getTokenSymbol())
    }
    const actionStatusType = computed(() => {
      if (props.txInfo.action === 'mint') return 'deposit'
      if (props.txInfo.action === 'burn') return 'withdraw'
      return props.txInfo.action
    })
    return {
      datetime,
      totalAmount,
      actionStatusType,
      txsAmount,
      targetChainType,
      burnToUrl,
      targetChainTxHashUrl,
      arId,
      accountId,
      formatMoney,
      displayWarningImg,
      formatInfo,
      capitalize,
      isOmitAmount,
      token
    }
  }
})

<template>
  <div class="bg-scanMain pt-20 pb-12 lg:pt-28">
    <Container>
      <div class="px-4">
        {{ t('transactions') }}
      </div>
      <Loading v-if="loading" />
      <TableCard
        v-else
        :tbody-list="txsTbodyList" />
      <Pagination
        :loading="loading"
        :current-page="currentPage"
        :has-next-page="isNextPage"
        @jump="handlePageChange" />
    </Container>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onActivated, onDeactivated, Ref } from 'vue'
import Container from '@/components/Container.vue'
import TableCard from '@/components/TableCard.vue'
import { getAllTxs } from '@/libs/api'
import Pagination from '@/components/common/Pagination.vue'
import Loading from '@/components/common/Loading.vue'
import { useI18n } from 'vue-i18n'
import { Txs } from '@/pages/interface'
import { handleErrorMsg } from '@/libs/utils'
export default defineComponent({
  components: {
    TableCard,
    Container,
    Pagination,
    Loading
  },
  setup () {
    const currentPage = ref(1)
    const pageCacheIndex = ref(0)
    const pageCachelist = ref<number[]>([])
    const isNextPage = ref(false)
    const pageId = ref(0)
    const loading = ref(false)
    const txsTbodyList:Ref<Txs[]> = ref([])
    const { t } = useI18n()
    const getTxsList = async () => {
      loading.value = true
      try {
        if (pageCachelist.value[pageCacheIndex.value] !== undefined) {
          pageId.value = pageCachelist.value[pageCacheIndex.value]
        }
        const everTxs = await getAllTxs(pageId.value)
        isNextPage.value = everTxs.hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (everTxs.hasNextPage) {
          pageId.value = everTxs.txs[everTxs.txs.length - 1].rawId
        }

        txsTbodyList.value = everTxs.txs
      } catch (e:any) {
        txsTbodyList.value = []
        handleErrorMsg(e, t)
      } finally {
        loading.value = false
      }
    }
    const handlePageChange = (page: number) => {
      currentPage.value = page
      pageCacheIndex.value = currentPage.value - 1
      getTxsList()
    }
    onActivated(() => {
      currentPage.value = 1
      pageCachelist.value = []
      isNextPage.value = false
      pageId.value = 0
      pageCacheIndex.value = 0
      getTxsList()
    })
    onDeactivated(() => {
      txsTbodyList.value = []
    })
    return {
      t,
      loading,
      txsTbodyList,
      handlePageChange,
      currentPage,
      isNextPage
    }
  }
})
</script>

<template>
  <tr
    class="h-12 relative"
  >
    <!-- everHash -->
    <td class="pl-4" :class="`${isBundleTx ? 'w-52' : ''}`">
      <img
        v-if="displayWarningImg"
        class="absolute positionImg"
        :src="require('../images/warning.svg')">
      <div class="hover:text-scanHoverColor text-scanMainColor">
        <router-link :to="`/tx/${txInfo.everHash}`">
          <MoreInfo :fixed-width="true" :info="formatInfo(txInfo.everHash)">
            {{ txInfo.everHash }}
          </MoreInfo>
        </router-link>
      </div>
    </td>
    <!-- 类型 -->
    <td :class="`${isBundleTx ? 'w-40' : 'w-20'}`">
      {{ isBundleTx ? 'Bundle' : capitalize(actionStatusType) }}
    </td>
    <!-- 状态 -->
    <td v-if="!isBundleTx" :class="`${isBundleTx ? 'w-32' : 'w-90px'}`">
      {{ capitalize(txInfo.status) }}
    </td>
    <!-- arId -->
    <td
      v-if="!isBundleTx"
      :class="`${txInfo.id === arId ? 'text-black' : 'hover:text-scanHoverColor text-scanMainColor'}
      ${isBundleTx ? 'w-36' : ''}`">
      <div v-if="!txInfo.id" class="min-w-90px">
        <img class="w-5 h-5 animate-spin-slow" :src="require('@/images/loading.svg')">
      </div>
      <div v-else>
        <router-link v-if="`${txInfo.id}`.toLowerCase() !== arId.toLowerCase()" :to="`/arId/${txInfo.id}`">
          <MoreInfo :info="formatInfo(txInfo.id)" :fixed-width="true">
            {{ txInfo.id }}
          </MoreInfo>
        </router-link>
        <div v-else>
          <MoreInfo :info="formatInfo(txInfo.id)" :fixed-width="true">
            {{ txInfo.id }}
          </MoreInfo>
        </div>
      </div>
    </td>
    <!-- 时间 -->
    <td :class="`${isBundleTx ? 'w-64' : 'w-165px'}`">
      {{ datetime }}
    </td>
    <!-- 来源 -->
    <td :class="`${isBundleTx ? 'w-52' : ''}`">
      <router-link v-if="txInfo.from.toLowerCase() !== accountId.toLowerCase()" :to="`/account/${txInfo.from}`" class="flex hover:text-scanHoverColor text-scanMainColor items-center">
        <img v-if="txInfo.from" :src="require('@/images/everPay-symbol.svg')" class="w-4 h-4 mr-1">
        <MoreInfo :info="formatInfo(txInfo.from)" :fixed-width="true">
          {{ txInfo.from }}
        </MoreInfo>
      </router-link>
      <div v-else class="flex text-black items-center">
        <img v-if="txInfo.from" :src="require('@/images/everPay-symbol.svg')" class="w-4 h-4 mr-1">
        <MoreInfo :info="formatInfo(txInfo.from)" :fixed-width="true">
          {{ txInfo.from }}
        </MoreInfo>
      </div>
    </td>
    <!-- 前往 -->
    <td :class="`${isBundleTx ? 'w-52' : ''}`">
      <!-- txInfo.action !== 'burn' 属于内部转账 -->
      <div v-if="txInfo.action !== 'burn'" class="cursor-pointer">
        <router-link v-if="txInfo.to.toLowerCase() !== accountId.toLowerCase()" :to="`/account/${txInfo.to}`" class="flex items-center hover:text-scanHoverColor text-scanMainColor">
          <img :src="require('@/images/everPay-symbol.svg')" class="w-4 h-4 mr-1">
          <MoreInfo :info="formatInfo(txInfo.to)" :fixed-width="true">
            {{ txInfo.to }}
          </MoreInfo>
        </router-link>
        <div v-else class="flex items-center text-black">
          <img :src="require('@/images/everPay-symbol.svg')" class="w-4 h-4 mr-1">
          <MoreInfo :info="formatInfo(txInfo.to)" :fixed-width="true">
            {{ txInfo.to }}
          </MoreInfo>
        </div>
      </div>
      <!-- 提现交易 -->
      <div v-else class="cursor-pointer">
        <a
          :href="burnToUrl"
          class="flex items-center text-scanMainColor hover:scanMainColor"
          target="_blank">
          <ChainTypeLogo :target-chain-type="targetChainType" class="mr-1 w-4 h-4" />
          <MoreInfo :info="formatInfo(txInfo.to)" :fixed-width="true">
            {{ txInfo.to }}
          </MoreInfo>
        </a>
      </div>
    </td>
    <!-- 金额 -->
    <td class="min-w-90px">
      <MoreInfo v-if="isOmitAmount(txInfo.amount)" :info="txsAmount(txInfo.amount)" class="cursor-pointer">
        {{ formatMoney(totalAmount(txInfo.amount))+ ' ' + (token ? token.symbol : '') }}
      </MoreInfo>
      <div v-else class="cursor-pointer whitespace-nowrap">
        {{ txsAmount(txInfo.amount) }}
      </div>
    </td>
    <td v-if="!isBundleTx" class="cursor-pointer">
      <MoreInfo v-if="isOmitAmount(txInfo.fee)" :info="txsAmount(txInfo.fee)">
        {{ formatMoney(totalAmount(txInfo.fee)) + ' ' + (token ? token.symbol : '') }}
      </MoreInfo>
      <div v-else class="w-24 whitespace-nowrap pl-1.5">
        {{ +txInfo.fee ? txsAmount(txInfo.fee) : '0' }}
      </div>
    </td>
    <td v-if="!isBundleTx" style="width:120px">
      <a
        v-if="txInfo.targetChainTxHash"
        target="_blank"
        class="flex items-center hover:text-scanHoverColor text-scanMainColor"
        :href="targetChainTxHashUrl">
        <ChainTypeLogo :target-chain-type="targetChainType" class="mr-1 w-4 h-4" />
        <MoreInfo :info="formatInfo(txInfo.targetChainTxHash)" :fixed-width="true">
          {{ txInfo.targetChainTxHash }}
        </MoreInfo>
      </a>
      <div v-else class="min-w-90px">
        <img v-if="txInfo.action === 'burn' && !txInfo.targetChainTxHash" class="animate-spin-slow w-5 h-5" :src="require('@/images/loading.svg')">
        <span v-else class="pl-1.5">/</span>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, computed, Ref, PropType } from 'vue'
import MoreInfo from '@/components/common/MoreInfo.vue'
import dayjs from 'dayjs'
import { fromDecimalToUnit } from '@/libs/everpay-js/utils/util'
import { getExplorerUrl, formatMoney, formatNumber, formatTargetChainType, formatTokenTag } from '@/libs/utils'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import capitalize from 'lodash/capitalize'
import ChainTypeLogo from './ChainTypeLogo.vue'
import { ChainType } from '@/libs/everpay-js'
export default defineComponent({
  components: {
    MoreInfo,
    ChainTypeLogo
  },
  props: {
    txInfo: {
      type: Object as PropType<any>,
      default: () => {}
    },
    isBundleTx: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const arId = computed<string>(() => route.params.arId ? route.params.arId.toString() : '')
    const accountId = computed(() => route.params.accountId ? route.params.accountId.toString() : '')
    const tokens = computed(() => store.state.tokenList)
    const datetime = dayjs(+props.txInfo.nonce).format('YYYY-MM-DD HH:mm:ss')
    const token = computed(() => formatTokenTag(props.txInfo, tokens.value))
    const targetChainType:Ref<any> = computed(() => {
      return formatTargetChainType(props.txInfo)
    })
    const displayWarningImg = computed(() => {
      try {
        const status = JSON.parse(props.isBundleTx ? props.txInfo.bundleErr : props.txInfo.internalStatus).status !== 'success'
        return status
      } catch {
        return true
      }
    })
    const burnToUrl = computed(() => {
      return props.txInfo.action === 'burn'
        ? getExplorerUrl({
          type: 'address',
          symbol: token.value?.symbol ?? '',
          value: props.txInfo.to
        }, targetChainType.value)
        : ''
    })
    const targetChainTxHashUrl = computed(() => {
      return props.txInfo.targetChainTxHash
        ? getExplorerUrl({
          type: 'tx',
          symbol: token.value?.symbol ?? '',
          value: targetChainType.value === ChainType.psntest ? props.txInfo.everHash : props.txInfo.targetChainTxHash
        }, targetChainType.value)
        : ''
    })
    const formatInfo = (info: string) => {
      return info.toString().slice(0, 8) + '...'
    }
    const totalAmount = (amount:string) => {
      return fromDecimalToUnit(amount, token.value?.decimals || 18)
    }
    const isOmitAmount = (amount:string) => {
      return totalAmount(amount).replaceAll(',', '').replace('.', '').length > 6
    }
    const getTokenSymbol = () => {
      if (token.value) {
        return token.value?.symbol
      } else if (props.txInfo.tokenSymbol) {
        return props.txInfo.tokenSymbol
      } else if (props.txInfo.tag) {
        return (props.txInfo.tag as string).split('-')[1].toUpperCase()
      } else {
        return 'undefined'
      }
    }
    const txsAmount = (amount:any) => {
      const amountInfo = totalAmount(amount)
      if (isOmitAmount(amount)) {
        return formatNumber(amountInfo, 6) + '...' + (getTokenSymbol())
      }
      return formatMoney(amountInfo) + ' ' + (getTokenSymbol())
    }
    const actionStatusType = computed(() => {
      if (props.txInfo.action === 'mint') return 'deposit'
      if (props.txInfo.action === 'burn') return 'withdraw'
      return props.txInfo.action
    })
    return {
      datetime,
      totalAmount,
      actionStatusType,
      txsAmount,
      targetChainType,
      burnToUrl,
      targetChainTxHashUrl,
      arId,
      accountId,
      formatMoney,
      displayWarningImg,
      formatInfo,
      capitalize,
      isOmitAmount,
      token
    }
  }
})
</script>

<style lang="scss">
.positionImg {
  width: 16px;
  height: 16px;
  top: 16px;
  left: -5px;
}

@media(max-width: 1280px) {
  .positionImg {
    width: 14px;
    height: 14px;
    top: 17px;
    left: 0px;
  }
}
</style>

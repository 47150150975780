<template>
  <div class="text-sm pt-4 overflow-x-auto xl:overflow-visible scrollbar pb-4">
    <table style="width: 1168px">
      <thead>
        <tr class="h-14 bg-everWhite1 ">
          <th
            v-for="(item,index) in txsThead"
            :key="index"
            class="text-left font-normal"
            :class="`
              ${index === txsThead.length-1 ? 'rounded-r-lg' : ''}
              ${index === 0 ? 'rounded-l-lg pl-4' : ''}`">
            {{ t(item) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <TableItem
          v-for="(item,index) in tbodyList"
          :key="index"
          :is-bundle-tx="currentTxOptions === 'Bundle'"
          :tx-info="item"
          class="h-12 relative"
          :class="index === tbodyList.length-1 ? '' : 'border-b border-black-500 border-solid'" />
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import TableItem from './TableItem.vue'
export default defineComponent({
  components: {
    TableItem
  },
  props: {
    tbodyList: {
      type: Array,
      default: () => []
    },
    currentTxOptions: {
      type: String,
      default: 'Transaction'
    }
  },
  setup (props) {
    const txsTheadList = [
      'everHash',
      'type',
      'status',
      'arId',
      'date_time',
      'from',
      'to',
      'amount',
      'tx_fee',
      'more'
    ]
    const bundleTxsTheadList = [
      'everHash',
      'type',
      // 'status',
      // 'arId',
      'date_time',
      'from',
      'to',
      'amount'
    ]
    const txsThead = computed(() => props.currentTxOptions === 'Transaction' ? txsTheadList : bundleTxsTheadList)
    const { t } = useI18n()
    return {
      t,
      txsThead
    }
  }
})
</script>

<style>

</style>
